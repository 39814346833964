import React from 'react';

import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import { StrapiButton } from '../../../atoms/Buttons/StrapiButton/StrapiButton';
import { Button, Media } from '../../../models/Strapi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';
import styles from './GameInfoModule.css';

type GamePanel = {
    id: number;
    count: string;
    type: string;
    specialText: string;
    media: Media;
    textColor?: string;
    alt?: string;
};

type GameBanner = {
    id: number;
    gamePanels: GamePanel[];
    Button: Button;
    subtitle: string;
    panelBackgroundColor: string;
    moduleScreenId?: string;
};

type Content = {
    id: number;
    text: string;
};

type GameInfoModuleProps = {
    title: string;
    moduleScreenId?: string;
    titleColor?: string;
    component: [GameBanner, Content, Button];
    background: Media;
    backgroundFallback: string;
};

const GameInfoModule = React.memo((props: GameInfoModuleProps) => {
    const {
        title,
        moduleScreenId,
        titleColor,
        component: [gameBanner, content, button],
    } = props;

    const getBackground = ({ background, backgroundFallback }: GameInfoModuleProps) => {
        const result = {} as React.CSSProperties;
        if (background) {
            result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
        }
        result.backgroundColor = backgroundFallback;
        return result;
    };

    const handleAnalytics = (ctaText, moduleScreenId) => {
        Analytics.trackEvent(
            Analytics.pageBuilderAdvantageAnalytics.mainModuleClick({
                moduleScreenId,
                ctaText,
            })
        );
    };

    return (
        <div className={styles.content} style={getBackground(props)}>
            <section className={styles.container}>
                <h2 style={{ color: titleColor }} className={styles.mainTitle}>
                    {title}
                </h2>
                <div className={styles.list}>
                    {gameBanner?.gamePanels.map((panel) => (
                        <span
                            key={panel.id}
                            className={styles.panel}
                            style={{ backgroundColor: gameBanner?.panelBackgroundColor }}
                        >
                            <div style={{ color: panel.textColor }} className={styles.caption}>
                                <p className={styles.count}>{panel.count}</p>
                                <p className={styles.type}>{panel.type}</p>
                                <p className={styles.specialText}>
                                    <ReactMarkdown
                                        components={{
                                            strong: ({ children }) => (
                                                <span className={classNames(styles.highlighted)}>{children}</span>
                                            ),
                                        }}
                                    >
                                        {panel.specialText}
                                    </ReactMarkdown>
                                </p>
                            </div>
                            <img src={UrlService.createStrapiMediaUrl(panel.media.url)} alt={panel.alt} />
                        </span>
                    ))}
                </div>
                <h3 className={styles.subtitle}>{gameBanner?.subtitle}</h3>
                <p className={styles.text}>{content?.text}</p>
                { button && <div className={styles.CTA}>
                    <StrapiButton
                        label={button?.label}
                        action={button?.action}
                        url={button?.url}
                        gradient={{
                            color1: button?.gradientColor1, //'#C28C2B'
                            color2: button?.gradientColor2, //'#E6C14E',
                            color3: button?.gradientColor3, //'#C28C2B',
                            textColor: button?.textColor, //'rgba(35, 31, 32, 1)',
                        }}
                        analyticsCallback={() => handleAnalytics(button?.label, moduleScreenId)}
                    />
                </div>
                }
            </section>
        </div>
    );
});

export default GameInfoModule;
